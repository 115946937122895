<template>
  <div id="app">
    <transition name="slide">
      <div class="slidein" v-if="open">
        <div
            style="background-color:"
            class="border border-dotted my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
        >
          <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
          >
            Sign In to e-Board
          </h2>
          <div class="intro-x mt-2 text-slate-400  text-center">
            A few more clicks to sign in to your account. Manage all your
            Boards in one place
          </div>
          <div class="intro-x mt-8">
            <input
                type="text"
                v-model="username"
                class="border border-dotted intro-x login__input form-control py-3 px-4 block"
                placeholder="Email"
            />
            <div class="flex gap-2">
              <input
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  class="border border-dotted intro-x login__input form-control py-3 px-4 block mt-4"
                  placeholder="Password"
                  @keypress.enter="userLogin"
              />
              <v-btn class="mt-5" icon v-if="!showPassword" @click="showPassword = true">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn class="mt-5" icon v-if="showPassword" @click="showPassword = false">
                <v-icon>mdi-eye-off</v-icon>
              </v-btn>
            </div>
          </div>
          <div
              class="intro-x flex text-slate-600 dark:text-slate-500 text-xs sm:text-sm mt-4"
          >
            <div class="flex items-center mr-auto">

            </div>
          </div>
          <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
            <v-btn link to="/login" style="text-transform: capitalize;" text outlined class=" xl:w-50 xl:mr-3 py-6">
              Forgot password ?
            </v-btn>
            <button
                @click="userLogin"
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
            >
              Login
            </button
            >
          </div>
          <div
              class="intro-x mt-10 xl:mt-24 text-slate-600 dark:text-slate-500 text-center xl:text-left"
          >
            If you get any challenge during login, kindly contact your institution
            administrator or reach out our support via
            &amp;
            <a class="text-primary dark:text-slate-200" target="_new_help_desk" href="https://helpdesk.gov.go.tz/login"
            >Help desk</a
            >
          </div>
        </div>

        <button class="close-btn" @click="toggle">
          <v-icon>mdi-close</v-icon>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  props: ["open"],
  data() {
    return {
      username: '',
      password: '',
      authDetails: '',
      showPassword: false,
      valid: true,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    };
  },

  methods: {
    toggle() {
      this.$emit("close");
    },
    ...mapActions({
      authenticateUser: 'AuthStore/authenticateUser'
    }),
    userLogin: async function () {
      this.$store.dispatch('showLoadingSpinner', true)
      this.authDetails = {
        'username': this.username,
        'password': this.password
      }
      await this.authenticateUser(this.authDetails)
      this.$store.dispatch('showLoadingSpinner', false)
    },
  },
};
</script>

<style>
.slidein {
  max-width: 600px;
  padding: 2em 3em;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background: rgb(255, 255, 255);
  height: 100%;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
}

/* before the element is shown, start off the screen to the right */
.slide-enter,
.slide-leave-active {
  right: -100%;
}

.close-btn {
  border: none;
  font-weight: bold;
  font-size: 2em;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5em;
}

/* General styles unrelated to slide in */
body {
  font-family: "Mulish", sans-serif;
}

.toggle {
  margin: 1em;
}

button {
  padding: 0.5em 1em;
  border-radius: 3em;
  font-size: 1.1em;
}

h1 {
  font-weight: 200;
}
</style>
