<template>
  <div>
    <div class="spinner-box23">
      <div class="flex gap-2">
        <v-text-field
            label="Password"
            name="Password"
            v-model="password1"
            prepend-icon="mdi-lock"
            :type="showPassword1 ? 'text' : 'password'"
            color="#3f51b5"
        />
        <v-btn class="mt-5" icon v-if="!showPassword1" @click="showPassword1 = true">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn class="mt-5" icon v-if="showPassword1" @click="showPassword1 = false">
          <v-icon>mdi-eye-off</v-icon>
        </v-btn>
      </div>
      <div class="flex gap-2">
        <v-text-field
            label="Re-Type Password"
            name="ReTypePassword"
            v-model="password2"
            prepend-icon="mdi-lock"
            :type="showPassword2 ? 'text' : 'password'"
            color="#3f51b5"
        />
        <v-btn class="mt-5" icon v-if="!showPassword2" @click="showPassword2 = true">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-btn class="mt-5" icon v-if="showPassword2" @click="showPassword2 = false">
          <v-icon>mdi-eye-off</v-icon>
        </v-btn>
      </div>
      <v-btn
        style="margin: 20px"
        link
        to="/landing_page"
        rounded
        color="#3f51b5"
        dark
      >
        <v-icon>mdi-arrow-left</v-icon> GO TO HOME PAGE
      </v-btn>

      <v-btn style="margin: 20px" @click="passwordReset" rounded color="#3f51b5" dark>RESET  <v-icon>mdi-lock-reset</v-icon></v-btn>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      userToken: null,
      absolute: true,
      overlay: true,
      password1: null,
      password2: null,
      showPassword1: false,
      showPassword2: false
    };
  },
  methods: {
    async passwordReset() {
      this.$store.dispatch("showLoadingSpinner", true);
      await this.$store.dispatch("passwordReset", {
        newPassword1: this.password1,
        newPassword2: this.password2,
        token: this.$route.params.userToken,
      });
      this.password1 = null;
      this.password2 = null;
      this.$store.dispatch("showLoadingSpinner", false);
      this.$router.push('/login')
    },
  },
};
</script>

<style  scoped>
.spinner-box23 {
  padding: 20px;
  position: fixed;
  width:90%;
  margin: auto;
  left: 0px;
  top: 40%;
  right: 0px;
  background: white;
  box-shadow: 0px 0px 9px 0px #888cbf;
  text-align: center;
}
</style>
