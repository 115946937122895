<template>
    <v-container fluid>
      <v-row >
        <v-col md="12" >
             <v-layout justify-space-between wrap>
        <v-card

     style="margin: 5px; padding: 5px"
    class="mx-auto"
    max-width="30%"
    outlined
   >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4" style="color:green">
           Board Chairperson
        </div>
        <v-list-item-title class="text-h7 mb-1" style="color:#3f51b5;">
        <strong> {{(boardStructure.boardChair||{}).memberFirstname}}  {{(boardStructure.boardChair||{}).memberLastname}}</strong>
        </v-list-item-title>
        <v-list-item-subtitle>{{(boardStructure.boardChair||{}).memberEmail}}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="100"
        color="white">
        <v-img :src="MEDIA_SERVER+(boardStructure.boardChair||{}).memberPhoto" class="w-full" style="max-width: 100px;"></v-img>
      </v-list-item-avatar>
    </v-list-item>
  </v-card>
             </v-layout>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12" >
          <v-layout justify-space-between wrap>
            <v-card
                style="margin: 5px; padding: 5px"
                class="mx-auto"
                max-width="30%"
                outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4" style="color:green">
                     Vice Chairperson
                  </div>
                  <v-list-item-title class="text-h7 mb-1" style="color:#3f51b5;">
                    <strong> {{(boardStructure.boardViceChair||{}).memberFirstname}}  {{(boardStructure.boardViceChair||{}).memberLastname}}</strong>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{(boardStructure.boardViceChair||{}).memberEmail}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar tile size="100" color="white">
                  <v-img :src="MEDIA_SERVER+(boardStructure.boardViceChair||{}).memberPhoto" class="w-full" style="max-width: 100px;"></v-img>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-layout>
        </v-col>
      </v-row>

        <v-row >
        <v-col md="12" >
             <v-layout justify-space-between wrap>
        <v-card

     style="margin: 5px; padding: 5px"
    class="mx-auto"
    max-width="30%"
    outlined
   >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4" style="color: green">
           Board Secretary
        </div>
        <v-list-item-title class="text-h7 mb-1" style="color: #3f51b5;">
       <strong>{{(boardStructure.boardSecretary||{}).memberFirstname}} {{(boardStructure.boardSecretary||{}).memberLastname}}</strong>
        </v-list-item-title>
        <v-list-item-subtitle style="color:#3f51b5">{{(boardStructure.boardSecretary||{}).memberEmail}} </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="100"
        color="white"
      ><v-img :src="MEDIA_SERVER+(boardStructure.boardSecretary||{}).memberPhoto" class="w-full" style="max-width: 100px;"></v-img></v-list-item-avatar>
    </v-list-item>
  </v-card>
             </v-layout>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-layout justify-space-between wrap>

              <v-card
              v-for="member in boardStructure.boardMembers"
              :key="member"
                style="margin: 5px; padding: 5px"
                class="mx-auto"
                max-width="30%"
                outlined
              >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4" style="color:#01579B">
          Member
        </div>
        <v-list-item-title class="text-h7 mb-1">
              {{member.memberFirstname}}   {{member.memberLastname}}

        </v-list-item-title>
        <v-list-item-subtitle>{{member.memberEmail}}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="100"
        color="white"
      ><v-img lazy-src="@/assets/skelaton.jpeg" :src="MEDIA_SERVER+member.memberPhoto" class="w-full" style="max-width: 100px;"></v-img></v-list-item-avatar>
    </v-list-item>
         </v-card>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  data: () => ({

  }),

  computed: {
     boardStructure() {
       var chairman = this.$store.state.MyboardStore.boardStructure;
      return chairman;
    }
  },

  async mounted() {
    await this.$store.dispatch("loadBoardStructure");

  }
};
</script>

<style>
</style>
