<template>
  <section class="intro-y" id="hero">
    <v-parallax
      class="intro-x"
      dark
      src="@/assets/img/landing.png"
      height="750"
    >
          <v-row class="main-title" align="center" justify="center">
            <v-col cols="10">
              <v-row align="center" justify="center">
                <v-col cols="12" md="6" xl="8">
                  <center>
                    <v-img
                      class="font-weight-bold mb-4 -intro-y"
                      width="200"
                      src="@/assets/img/eBodilogo.png"
                    ></v-img>
                  </center>
                  <h1
                    style="color: lightblue"
                    class="font-weight-light display-1 intro-x"
                  >
                    Schedule Meeting, Set agenda, <br />
                    Organize Board Packs and take minutes, anywhere, <br />
                    anytime, on any device. <br />
                  </h1>
                  <v-btn
                    rounded
                    outlined
                    large
                    dark
                    @click="$vuetify.goTo('#features')"
                    class="mt-5 intro-x"
                    style="color: lightblue"
                  >
                    Explore Features
                    <v-icon style="color: lightblue" class="ml-2"
                      >mdi-arrow-down</v-icon
                    >
                  </v-btn>
                  <div class="video d-flex align-center py-4 intro-y">
                    <a @click.stop="dialog = true" class="playBut">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                        x="0px"
                        y="0px"
                        width="60px"
                        height="60px"
                        viewBox="0 0 213.7 213.7"
                        enable-background="new 0 0 213.7 213.7"
                        xml:space="preserve"
                      >
                        <polygon
                          class="triangle"
                          id="XMLID_18_"
                          fill="none"
                          stroke-width="7"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                          points="73.5,62.5 148.5,105.8 73.5,149.1 "
                        />

                        <circle
                          class="circle"
                          id="XMLID_17_"
                          fill="none"
                          stroke-width="7"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-miterlimit="10"
                          cx="106.8"
                          cy="106.8"
                          r="103.3"
                        />
                      </svg>
                    </a>
                    <p style="color: lightblue" class="subheading ml-2 mb-0">
                      More about e-Board
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  xl="4"
                  class="hidden-sm-and-down intro-x"
                >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
      <div class="svg-border-waves text-white">
        <v-img src="@/assets/img/borderWaves.svg" />
      </div>
    </v-parallax>
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <div class="intro-x flex items-center h-10">
          <h1
            class="display-1 white--text text-lg font-medium truncate mr-auto"
          >
            e-Board Features
          </h1>
          <button
            data-carousel="important-notes"
            data-target="prev"
            class="tiny-slider-navigator btn px-2 border-slate-300 text-slate-600 dark:text-slate-300 mr-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide w-4 h-4"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg></button
          ><button
            data-carousel="important-notes"
            data-target="next"
            class="tiny-slider-navigator btn px-2 border-slate-300 text-slate-600 dark:text-slate-300 mr-2"
            @click="gridView = !gridView"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide w-4 h-4"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
        </div>
        <v-col v-if="gridView" cols="10">
          <v-row align="center" justify="space-around">
            <v-col cols="12" class="text-center"> </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="text-center intro-x zoom-in"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card zoom-top hover:bg-slate-500"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>

        <v-col md="12" v-else>
          <div class="grid grid-cols-12 gap-6 mt-5">
            <div
              class="intro-y col-span-12 md:col-span-6 h-400"
              v-for="feature in features"
              :key="feature"
            >
              <div class="box" style="height: 100px">
                <div class="flex flex-col lg:flex-row items-center p-5">
                  <div class="w-48 h-100 lg:w-12 lg:h-12 image-fit lg:mr-1">
                    <img class="rounded-full" :src="feature.img" />
                  </div>
                  <div
                    class="w-30 lg:ml-2 lg:mr-auto text-left lg:text-left mt-3 lg:mt-0"
                  >
                    <span class="font-medium info--text">{{
                      feature.title
                    }}</span>
                    <div class="text-slate-500 text-sm mt-0.5">
                      {{ feature.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" max-width="60%">
      <v-card
        :loading="loadingVideo"
        class="-intro-x overflow-y-hidden overflow-x-hidden"
        height="600px"
      >
        <youtube
          :video-id="videoId"
          @ready="ready"
          @playing="playing"
          :resize="true"
          :player-vars="playerVars"
        ></youtube>
      </v-card>
    </v-dialog>
    <div class="svg-border-waves">
      <img src="~@/assets/img/wave2.svg" />
    </div>

  </section>
</template>

<script>



export default {
  data() {
    return {
      options: {
        mouseControls: true,
        touchControls: true,
        minHeight: 500.0,
        minWidth: 200.0,
        scale: 1.0,
        scaleMobile: 1.0,
      },
      gridView: true,
      dialog: false,
      loadingVideo: true,
      videoId: "sijAP7SxQxI",
      playerVars: {
        autoplay: 0,
      },
      features: [
        {
          img: require("@/assets/img/meetingscedule.png"),
          title: "Meeting Schedule",
          text: "With many commitments and a busy diary, e-Board makes it easy for you to view your meeting schedule for your boards and committees.",
        },
        {
          img: require("@/assets/img/agenda.png"),
          title: "Agenda Builder",
          text: "Quickly build, organise and share the meeting agenda with your directors, and give them the tools to fully engage in a meeting.",
        },
        {
          img: require("@/assets/img/minutes.png"),
          title: "Minutes",
          text: "Capture meeting topic notes, decisions and actions, and see it all turned into a professional document to send to the board.",
        },
        {
          img: require("@/assets/img/vote.png"),
          title: "Resolutions",
          text: "Your board can vote during the meetings and even between meetings when a timely decision is needed, or for passing simple resolutions to save time in the meeting.",
        },
        {
          img: require("@/assets/img/decision.png"),
          title: "Resolution Register",
          text: "All decisions made at your meetings stored in one location in a live repository of critical business decisions that can be searched at the click of a button.",
        },
        {
          img: require("@/assets/img/packs.png"),
          title: "Board Packs",
          text: "Automatically combine your meeting papers into an easy to read board pack using e-Board board pack builder.",
        },
        {
          img: require("@/assets/img/circular.png"),
          title: "Circular Resolutions",
          text: "e-Board enables your board and committee members to make important decisions between your scheduled meetings.",
        },
        {
          img: require("@/assets/img/interest.png"),
          title: "Interest Register",
          text: "Declare your conflict of interests in a central register to keep compliance simple for your organisation.",
        },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  methods: {
    ready(event) {
      this.loadingVideo = false;
      this.player = event.target;
    },
    playing(event) {
      console.log(event);
      // The player is playing a video.
    },
    change() {
      // when you change the value, the player will also change.
      // If you would like to change `playerVars`, please change it before you change `videoId`.
      // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
      // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
      this.videoId = "another video id";
    },
    stop() {
      this.player.stopVideo();
    },
    pause() {
      this.player.pauseVideo();
    },
  },
};
</script>

<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.3s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style>
.main-title {
  color: #fff;
  z-index: 999;
}
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>

<style>
section {
  position: relative;
}
</style>
