var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('v-simple-table',{staticStyle:{"width":"100%"}},[[(!_vm.selectedAgenda)?_c('v-overlay',{attrs:{"absolute":_vm.absolute,"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1):_vm._e(),_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"width":"10%"}}),_c('th',{staticClass:"text-left",staticStyle:{"width":"80%"}})])]),_c('tbody',[(_vm.boardSetting().showAgendaPurpose)?_c('tr',[_c('td',[_c('strong',[_vm._v("Purpose :")])]),_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm.selectedAgenda.itemDetails.itemPuporse)+" "),_c('v-menu',{attrs:{"close-on-content-click":false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.meetingHasPublishedAgenda)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1):_vm._e()]}}],null,false,2245608201)},[_c('v-card',{attrs:{"width":"700"}},[_c('v-card-text',{staticClass:"flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agendaPurpose),expression:"agendaPurpose"}],staticClass:"w-full p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",staticStyle:{"border":"1px solid black"},attrs:{"type":"text","placeholder":"Purpose of the agenda","required":""},domProps:{"value":(_vm.agendaPurpose)},on:{"input":function($event){if($event.target.composing){ return; }_vm.agendaPurpose=$event.target.value}}}),_c('v-btn',{staticClass:"ma-1 ml-2 indigo--text",attrs:{"small":"","outlined":"","icon":""},on:{"click":function($event){return _vm.editAgendaPurpose('Purpose')}}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],1)])]):_vm._e(),(_vm.boardSetting().showAgendaPresenter)?_c('tr',[_c('td',[_c('strong',[_vm._v("Presenter :")])]),_c('td',[_c('v-menu',{attrs:{"app":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"text-transform":"capitalize"},attrs:{"slot":"activator","color":"indigo","small":"","text":""},slot:"activator"},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.presenter)+" ")])]}}],null,false,3684483123)},[(!_vm.meetingHasPublishedAgenda)?_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.getManagementMembers),function(member){return _c('v-list-item',{key:member.username,attrs:{"link":""}},[(member)?_c('v-list-item-title',{on:{"click":function($event){return _vm.changePresenter(_vm.selectedAgenda.itemKey,member)}}},[_vm._v(" "+_vm._s(member.firstName)+" "+_vm._s(member.lastName)+" ")]):_c('v-list-item-title',{on:{"click":function($event){return _vm.changePresenter(_vm.selectedAgenda.itemKey,member)}}},[_vm._v(" No Presenter ")])],1)}),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.changePresenter(_vm.selectedAgenda.itemKey,"None")}}},[_vm._v(" No Presenter ")])],1)],2):_vm._e()],1)],1)]):_vm._e(),(_vm.boardSetting().showAgendaDuration)?_c('tr',[_c('td',[_c('strong',[_vm._v("Duration :")])]),_c('td',{staticClass:"Editable"},[_c('span',{on:{"blur":function (event) { return _vm.onPurposeInput(event,_vm.selectedAgenda.itemDetails.itemKey,'Time'); }}},[_vm._v(" "+_vm._s(_vm.selectedAgenda.itemDetails.itemDuration)+" ")]),_vm._v(" Min "),_c('v-menu',{attrs:{"close-on-content-click":false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.meetingHasPublishedAgenda)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1):_vm._e()]}}],null,false,2245608201)},[_c('v-card',{attrs:{"width":"700"}},[_c('v-card-text',{staticClass:"flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agendaDuration),expression:"agendaDuration"}],staticClass:"w-full p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",staticStyle:{"border":"1px solid black"},attrs:{"type":"text","placeholder":"Duration of the agenda","required":""},domProps:{"value":(_vm.agendaDuration)},on:{"input":function($event){if($event.target.composing){ return; }_vm.agendaDuration=$event.target.value}}}),_c('v-btn',{staticClass:"ma-1 ml-2 indigo--text",attrs:{"small":"","outlined":"","icon":""},on:{"click":function($event){return _vm.editAgendaDuration('Time')}}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1)],1)],1)]):_vm._e(),_c('tr')])]],2),_c('EditAgendaDetails',{attrs:{"isEditAgendaDetails":_vm.isEditAgendaDetails,"itemDetail":_vm.itemDetail},on:{"closeEditingAgendaDetails":function($event){_vm.isEditAgendaDetails=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }